import ProfileBar from '../Components/ProfileBar';
import { useUserProfile } from 'profiles/queries/profileHooks';
import Loader from 'components/Loader';
import { useEffect, useState } from 'react';
import { getDateInDDMMYYYY } from 'constants/DateFunctions';

export default function Account() {
  const [data, setData] = useState([]);
  const profileQueryData = useUserProfile();
  const userRole = profileQueryData?.data.message.primary_role;
  useEffect(() => {
    if (userRole === 'institution') {
      setData([
        { header: 'Name', value: profileQueryData?.data.message.full_name },
        { header: 'SFA ID', value: profileQueryData?.data.message.sfa_id },
        // { header: 'Gender', value: profileQueryData?.data.message.gender },
        { header: 'Email', value: profileQueryData?.data.message.email },
        { header: 'Phone Number', value: profileQueryData?.data.message.phone_number },
        // { header: 'School Name', value: profileQueryData?.data.message.school_name },
        // { header: 'City', value: profileQueryData?.data.message.city },
      ]);
    } else
      setData([
        { header: 'Name', value: profileQueryData?.data.message.full_name },
        { header: 'SFA ID', value: profileQueryData?.data.message.sfa_id },
        { header: 'Gender', value: profileQueryData?.data.message.gender },
        { header: 'Email', value: profileQueryData?.data.message.email },
        { header: 'Phone Number', value: profileQueryData?.data.message.phone_number },
        {
          header: 'Date of Birth',
          value: getDateInDDMMYYYY(profileQueryData?.data.message.date_of_birth),
        },
        { header: 'School Name', value: profileQueryData?.data.message.school_name },
        // { header: 'City', value: profileQueryData?.data.message.city },
      ]);
  }, [profileQueryData?.data]);

  return (
    <>
      <ProfileBar head="My Account" />
      <div className="px-6 py-6 lg:py-8 lg:px-40">
        <div className="pt-6 px-6 lg:px-8 bg-white border border-gray-300 rounded-md ">
          <h1 className="font-bold text-lg lg:text-xl text-gray-900">General</h1>
          {/* <div className={'w-full md:w-11/12'}> */}
          <div
            className="flex-1 my-4 py-3 px-5 bg-blue-100 text-blue-900 text-sm rounded-md border border-blue-200"
            role="alert"
          >
            To edit your Profile details, kindly contact us at{' '}
            <a
              target="_blank"
              href="mailto:UK.support@sfaplay.com?subject=SFA Uttarakhand Championship 2022 - Profile Update Request&body=Please add your profile change query here"
              rel="noreferrer"
            >
              UK.support@sfaplay.com
            </a>{' '}
            with the required changes.
          </div>
          <table className="min-w-full table mt-4">
            <tbody className="bg-white block sm:table-row-group">
              {data.length === 0 ? (
                <Loader />
              ) : (
                data?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`block ${
                        index !== 0 ? 'border-t border-gray-300' : ''
                      } flex flex-col md:flex-row gap-0.5 py-2`}
                    >
                      <td
                        style={{ borderTopWidth: '0' }}
                        className="w-36 mobile-padding text-left text-sm font-medium text-gray-650"
                      >
                        <p className="inline-block">{item.header}</p>
                      </td>
                      <td
                        style={{ borderTopWidth: '0' }}
                        className="mobile-padding text-left text-sm font-semibold sm:font-medium md:text-gray-750 text-gray-850"
                      >
                        <p className="inline-block">{item.value ? item.value : '-'}</p>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
