import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import supportIcon from '../../assets/img/supportIcon.svg';

export default function ProfileBar({
  head,
  redirectTo,
  act,
  isDark,
  className,
  showAddEvents,
  user,
  tournamentId,
}) {
  const history = useHistory();

  return (
    <div
      className={`px-6 py-4 md:py-8 md:px-11 md:bg-white flex lg:gap-4 justify-between lg:justify-start items-center text-roboto ${
        isDark ? 'bg-gray-750' : 'bg-white'
      } ${className}`}
    >
      <div
        data-cy="back_btn"
        className="flex items-center cursor-pointer"
        onClick={() => {
          redirectTo ? history.push(redirectTo) : history.goBack();
        }}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={`md:fa-xl w-3 md:text-gray-750 ${isDark ? 'text-white' : 'text-gray-750'}`}
        />
        <p
          data-cy="head"
          className={`md:text-xl text-small md:text-gray-750 ${
            isDark ? 'text-white' : 'text-gray-750'
          } md:ml-4 ml-2.5 font-bold`}
        >
          {head}
        </p>
      </div>
      {act && (
        <Link to="/act/support">
          <div className="flex">
            <img src={supportIcon} alt="icon" />
            <span className="text-sm text-deep-gray font-bold ml-3">Support</span>
          </div>
        </Link>
      )}

      {showAddEvents && (
        <button
          onClick={() => {
            history.push(`/${user}/registration/${tournamentId}/tournament-form`);
          }}
        >
          <div className="flex">
            <FontAwesomeIcon
              icon={faPlus}
              className={`${isDark ? 'text-white' : 'text-gray-750'}`}
            />
            <span className={`text-sm font-bold ml-3 ${isDark ? 'text-white' : 'text-gray-750'}`}>
              Add Events
            </span>
          </div>
        </button>
      )}
    </div>
  );
}
